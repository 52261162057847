import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import UqLayoutLegalPage from '../components/uqLayoutLegalPage';
import SEO from '../components/seo';

import * as styles from './legal.module.scss';

export default function LegalTermsPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container legal-terms-page">
          <UqLayoutLegalPage
            pageInfo={{
              pageName: 'Legal',
              headerTitle: 'Legal',
              headerSubtitle: '',
              headerInfo: '',
              active: 'terms',
            }}
            headerRowClass="legal-page"
          >
            <SEO title="Legal - Legal unitQ terms of use" />
            <Container className="text-center content-wrapper">
              <Row className="mt-5 mb-4">
                <Col className="layout-col align-center">
                  <div className="legal-content">
                    <h1>
                      PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED
                      BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE
                      TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES
                      IN ANY MANNER
                    </h1>
                    <p className="c0">&nbsp;</p>
                    <h1>Terms of Use</h1>
                    <p className="c0">Effective date: November 12th 2018</p>
                    <p className="c0">
                      <span>
                        Welcome to www.unitq.com. Please read on to learn the rules
                        and restrictions that govern your use of our website(s),
                        products, services and applications (the
                        &ldquo;Services&rdquo;). If you have any questions, comments,
                        or concerns regarding these terms or the Services, please
                        contact us at{' '}
                      </span>
                      <a href="mailto:help@unitq.com">help@unitq.com</a>
                      <span>.</span>
                    </p>
                    <p className="c0">
                      <span>
                        These Terms of Use (the &ldquo;Terms&rdquo;) are a binding
                        contract between you and unitQ inc. (&ldquo;unitQ,&rdquo;
                        &ldquo;we&rdquo; and &ldquo;us&rdquo;). You must agree to and
                        accept all of the Terms, or you don&rsquo;t have the right to
                        use the Services. Your using the Services in any way means
                        that you agree to all of these Terms, and these Terms will
                        remain in effect while you use the Services. These Terms
                        include the provisions in this document, as well as those in
                        the{' '}
                      </span>
                      <span>
                        <a target="_blank" href="/legal-privacy/">
                          Privacy Policy
                        </a>
                      </span>
                      <span>&nbsp;and </span>
                      <span>
                        <a target="_blank" href="/legal-copyright/">
                          Copyright Dispute Policy
                        </a>
                      </span>
                      <span>.</span>
                    </p>
                    <p className="c0">
                      <b>NOTICE: Please read these Terms carefully</b>
                      <span>
                        . They cover important information about Services provided to
                        you and any charges, taxes, and fees we bill you.{' '}
                      </span>
                      <b>
                        These Terms include information about future changes to these
                        Terms, limitations of liability, a class action waiver, and
                        resolution of disputes by arbitration instead of by a court
                        proceeding.
                      </b>
                    </p>
                    <p className="c0">
                      <span>&nbsp;</span>
                    </p>
                    <h1>Will these Terms ever change?</h1>
                    <p className="c0">
                      <span className="c1 c4">
                        We are constantly trying to improve our Services, so these
                        Terms may need to change along with the Services. We reserve
                        the right to change the Terms at any time, but if we do, we
                        will bring it to your attention by placing a notice on the
                        www.unitq.com website, by sending you an email, and/or by some
                        other means.
                      </span>
                    </p>
                    <p className="c0">
                      <span>
                        If you don&rsquo;t agree with the new Terms, you are free to
                        reject them; unfortunately, that means you will no longer be
                        able to use the Services. If you use the Services in any way
                        after a change to the Terms is effective, that means you agree
                        to all of the changes.
                      </span>
                    </p>
                    <p className="c0">
                      <span>
                        Except for changes by us as described here, no other amendment
                        or modification of these Terms will be effective unless in
                        writing and signed by both you and us.
                      </span>
                    </p>
                    <p className="c0">
                      <span>&nbsp;</span>
                    </p>
                    <h1>What about my privacy?</h1>
                    <p className="c0">
                      <span>
                        unitQ takes the privacy of its users very seriously. For the
                        current unitQ Privacy Policy,{' '}
                      </span>
                      <span>
                        <a target="_blank" href="/legal-privacy/">
                          please click here
                        </a>
                      </span>
                      <span>.</span>
                    </p>
                    <p className="c0">
                      <span>
                        The Children&rsquo;s Online Privacy Protection Act
                        (&ldquo;COPPA&rdquo;) requires that online service providers
                        obtain parental consent before they knowingly collect
                        personally identifiable information online from children who
                        are under 13. We do not knowingly collect or solicit
                        personally identifiable information from children under 13; if
                        you are a child under 13, please do not attempt to register
                        for the Services or send any personal information about
                        yourself to us. If we learn we have collected personal
                        information from a child under 13, we will delete that
                        information as quickly as possible. If you believe that a
                        child under 13 may have provided us personal information,
                        please contact us at{' '}
                      </span>
                      <a href="mailto:help@unitq.com">help@unitq.com</a>
                      <span>.</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </UqLayoutLegalPage>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
